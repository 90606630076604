<template>
  <div>
    <van-form validate-first @failed="onFailed">
      <!-- 通过 pattern 进行正则校验 -->
      <van-field
          v-model="orderUser.username"
          label="商户"
          name="pattern"
          placeholder="请选择商户"
          readonly
      >
        <template #button>
          <van-button size="small" type="primary" @click="showPopup">选择商户</van-button>
        </template>
      </van-field>
      <!-- 通过 validator 进行函数校验 -->
      <van-field
          v-model="orderUser.phone"
          label="手机号码"
          name="validator"
          placeholder="请选择商户"
          readonly
      />

      <van-field
          v-model="deliveryAddress"
          is-link
          readonly
          label="送货地址"
          placeholder="请选择所在地区"
          @click="selectAddressPopupShow = true"
      />
      <van-popup v-model="selectAddressPopupShow" round position="bottom">
        <van-cascader
            v-model="cascaderValue"
            title="请选择所在地区"
            :options="options"
            :field-names="fieldNames"
            @close="selectAddressPopupShow = false"
            @finish="onFinish"
            @change="onAreaChange"
        />
      </van-popup>

      <van-field
          readonly
          clickable
          name="datetimePicker"
          :value="orderInfo.orderDatetimeStr"
          label="时间选择"
          placeholder="点击选择时间"
          @click="showPicker = true"
      />
      <van-popup v-model="showPicker" position="bottom">
        <van-datetime-picker
            v-model="orderInfo.orderDatetime"
            type="datetime"
            title="选择完整时间"
            :min-date="minDate"
            :max-date="maxDate"
            @confirm="onConfirm"
            @cancel="showPicker = false"
        />
      </van-popup>
      <van-divider
          :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }"
      >
        商品信息
      </van-divider>

      <div ref="container" style="padding-bottom: 35px">
        <van-sticky :container="container" :offset-top="50">
          <van-button type="primary" style="margin-left: 15px" @click="showOrderPopup()">历史订单</van-button>
          <van-button type="primary" style="margin-left: 15px" @click="showProductPopup()">全部商品</van-button>
        </van-sticky>

        <div v-for="item in selectedProducts" :key="item.id" :name="item.id">

          <div class="van-coupon">
            <div>
              <van-row>
                <van-col span="16">
                  <div style="height: 20px; padding-top: 5px;">
                    <span style="font-size: 12px; color: #323233; margin-left: 10px">{{ item.storeName }}</span>
                  </div>
                </van-col>
                <van-col span="8">
                  <div style="height: 20px; padding-top: 5px; text-align: right;">
                    <van-icon name="more-o" color="#1989fa" size="25px" style="margin-right: 5px"
                              @click="editProduct(item)"/>
                  </div>
                </van-col>
              </van-row>
            </div>
            <div class="van-coupon__content" style="padding: 0" @click="getAccountOrderInfo(item.id)">
              <div class="van-coupon__head" style="max-width: 80px">
                <h3 class="van-coupon__amount" style="font-size: 16px">{{ item.totalPrice }}<span>元</span></h3>
                <p class="van-coupon__condition">数量：{{ item.totalNum }}</p>
              </div>
              <div class="van-coupon__body">
                <p class="van-coupon__name">{{ item.storeInfo }}</p>
                <p class="van-coupon__valid">{{ item.sku }}</p>
              </div>
            </div>
          </div>
        </div>


        <van-popup v-model="showEditProduct" style="width: 90%; max-height: 90%; padding-bottom: 10px">
          <van-card
              :desc="selectedProduct.storeInfo"
              :title="selectedProduct.storeName"
              :thumb="selectedProduct.image"
              :origin-price="selectedProduct.price"
          />
          <div v-for="item in selectedProduct.storeProductAttrValues" :key="item.unique" :name="item.unique">
            <div role="button" tabindex="0"
                 class="van-cell van-cell--clickable van-cell--center van-cell--borderless van-contact-card van-contact-card--edit">
              <!--          <van-icon name="cash-on-deliver"/>-->
              <div class="van-cell__value van-cell__value--alone van-contact-card__value">
                <!--            <van-field v-model="item.sku" />-->
                <span>{{ item.sku }}</span>
                <van-field v-model="item.price" left-icon="refund-o" @focus="clearPrice(item)"
                           size="small" rows="1" type="number" style="margin-bottom: 3px; width: 70%"
                           @input="onChangeAmount"/>
                <van-stepper v-model.number="item.number"
                             step="1"
                             integer
                             input-width="40px"
                             button-size="40px"
                             @change="onChangeAmount"/>
              </div>
              <van-icon name="delete-o" size="20px" @click="onDeleteProductAttr(item.productAttrUnique)"/>
            </div>
          </div>

          <van-button type="primary" size="small" block style="width: 90%; margin: 5px auto 0;"
                      @click="submitEditProduct">
            确定
          </van-button>
        </van-popup>


        <!--        <div v-for="item in candidateProducts" :key="item.productAttrUnique" :name="item.storeName">-->
        <!--          <van-card-->
        <!--              :price="item.price"-->
        <!--              :desc="item.sku"-->
        <!--              :title="item.storeName"-->
        <!--              :thumb="item.image"-->
        <!--              style="margin-bottom: 10px"-->
        <!--          >-->
        <!--            <template #tags>-->
        <!--              <van-field v-model="item.price" left-icon="refund-o" @focus="clearPrice(item)" size="small" rows="1" type="number" @input="onChangeNumberOfStepper"/>-->
        <!--            </template>-->
        <!--            <template #price>-->
        <!--              <van-stepper v-model.number="item.number"-->
        <!--                           step="1"-->
        <!--                           integer-->
        <!--                           input-width="40px"-->
        <!--                           button-size="40px"-->
        <!--                           style="position: relative; top: 6px;"-->
        <!--                           @change="onChangeNumberOfStepper"/>-->
        <!--            </template>-->
        <!--            <template #footer>-->
        <!--              <van-button type="warning" size="mini" @click="deleteCandidateProduct(item.productAttrUnique)">删除-->
        <!--              </van-button>-->
        <!--            </template>-->
        <!--          </van-card>-->
        <!--        </div>-->
      </div>

      <div style="margin: 16px;">
        <van-submit-bar :price="amount" button-text="提交订单" @submit="onSubmitForOrder"/>
      </div>
    </van-form>

    <!--    商户列表-->
    <van-popup v-model="userPopupShow" round position="left" :style="{ height: '100%' }">
      <van-contact-card type="add" @click="onAdd"/>
      <div role="button" tabindex="0"
           class="van-cell van-cell--clickable van-cell--center van-cell--borderless van-contact-card van-contact-card--edit"
           v-for="item in users" :key="item.id" :name="item.id" style=""
           @click="onSelectUser(item.id)">
        <van-image
            round
            fit="cover"
            width="3rem"
            height="3rem"
            :src="item.avatar"
        />
        <div class="van-cell__value van-cell__value--alone van-contact-card__value">
          <div>姓名：{{ item.username }}</div>
          <div>电话：{{ item.phone }}</div>
        </div>
        <van-tag v-show="item.isSelected" plain type="primary" class="van-icon-arrow" style="margin-left: 4px;">选中
        </van-tag>
      </div>
    </van-popup>

    <!--历史订单弹出层-->
    <van-popup
        v-model="showList"
        round
        position="bottom"
        style="height: 90%; padding-top: 15px;"
    >
      <div style="padding-bottom: 90px;">
        <div style="position: relative; margin: 0 12px 12px;
          overflow: hidden;
          background-color: #fff;
          border-radius: 8px;
          box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);"
             v-for="item in historyOrders" :key="item.id" :name="item.id">
          <div style="position: relative; padding: 8px 16px;
        font-size: 12px;
        margin-bottom: 20px;">
            <div style="position: absolute; left: 10px">{{ item.orderTime }}</div>
            <div style="position: absolute; right: 15px;">
              <van-checkbox v-model="item.checked" :name="item.id" icon-size="25px"
                            @click="productCheckboxGroupToggleForHistoryOrder(item, item.accountOrderInfoList)">全选
              </van-checkbox>
            </div>
          </div>
          <div class="van-card" v-for="subItem in item.accountOrderInfoList" :key="subItem.id" :name="subItem.id">
            <div class="van-card__header">
              <a class="van-card__thumb">
                <div class="van-image" style="width: 100%; height: 100%;">
                  <img :src="subItem.image" class="van-image__img" style="object-fit: cover;">
                </div>
              </a>
              <div class="van-card__content">
                <div>
                  <div class="van-card__title van-multi-ellipsis--l2">{{ subItem.storeName }}</div>
                  <div class="van-card__desc van-ellipsis">{{ subItem.sku }}</div>
                </div>
                <div class="van-card__bottom">
                  <div class="van-card__price">
                    <div>
                      <span class="van-card__price-currency">¥</span>
                      <span class="van-card__price-integer">{{ subItem.price }}</span>
                      <!--                    .<span class="van-card__price-decimal">00</span>-->
                      <div class="van-card__num" style="margin-left: 8px;">x{{ subItem.number }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div style="position: absolute;right: 5px; top: 39%;">
                <van-checkbox v-model="subItem.checked" ref="checkboxesRef" icon-size="30px"
                              :name="subItem.productAttrUnique"
                              @click="productCheckboxToggleForHistoryOrder(item.id, subItem)"></van-checkbox>
              </div>
            </div>
          </div>
          <div style="text-align: right; font-size: 14px; font-weight: 500;     padding-right: 15px;
         margin-top: 12px;     padding-bottom: 5px;
         padding-top: 5px;">
            <span>共计</span><span>{{ item.totalNum }}</span><span>件商品，总金额为：</span>
            <span class="van-card__price-currency" style="color: #ee0a24;">¥</span>
            <span class="van-card__price-integer" style="color: #ee0a24;">{{ item.totalPrice }}</span>
          </div>
        </div>
      </div>

      <van-submit-bar button-text="确认" @submit="onSubmit">
        <template #tip>
          {{ selectedOrderProductTip }}
        </template>
      </van-submit-bar>
    </van-popup>


    <!--全部商品弹出层-->
    <!--    <van-popup-->
    <!--        v-model="isShowProductPopup"-->
    <!--        round-->
    <!--        position="bottom"-->
    <!--        style="height: 90%; padding-top: 15px;"-->
    <!--    >-->
    <!--      <van-tabs v-model="productTabsActive">-->
    <!--        <van-tab title="商品列表">-->
    <!--          <div>-->
    <!--            <van-row v-if="!empty">-->
    <!--              <div class="address-popup-load">-->
    <!--                <van-loading v-if="showLoading && init" type="spinner" color="#1989fa"/>-->
    <!--              </div>-->
    <!--              <van-col span="6">-->
    <!--                &lt;!&ndash;                <van-sticky z-index="-1" :container="sidebarContainer">&ndash;&gt;-->
    <!--                <van-sidebar style="width: 100%" v-model="activeKey">-->
    <!--                  <div v-for="item in items" :key="item.id" :name="item.id">-->
    <!--                    <van-sidebar-item :title="item.cateName" @click="showAddAlert(item.id)"/>-->
    <!--                  </div>-->
    <!--                </van-sidebar>-->
    <!--                &lt;!&ndash;                </van-sticky>&ndash;&gt;-->
    <!--              </van-col>-->
    <!--              <van-col span="18">-->

    <!--                <van-pull-refresh v-model="refreshing" @refresh="onRefresh" v-if="!init">-->
    <!--                  <van-list-->
    <!--                      v-model="loading"-->
    <!--                      :finished="finished"-->
    <!--                      finished-text="没有更多了"-->
    <!--                      @load="onLoad"-->
    <!--                  >-->

    <!--                    <div v-for="item in goods" :key="item.id" :name="item.id">-->
    <!--                      <div class="van-card">-->
    <!--                        <div class="van-card__header">-->
    <!--                          <a class="van-card__thumb">-->
    <!--                            <div class="van-image" style="width: 100%; height: 100%;" @click="showImages(item.id)">-->
    <!--                              <img :src="item.image" class="van-image__img" style="object-fit: cover;">-->
    <!--                            </div>-->
    <!--                          </a>-->
    <!--                          <div class="van-card__content">-->
    <!--                            <div>-->
    <!--                              <div class="van-card__title van-multi-ellipsis&#45;&#45;l2">{{ item.storeName }}</div>-->
    <!--                              <div class="van-card__desc van-ellipsis">{{ item.storeInfo }}</div>-->
    <!--                            </div>-->
    <!--                            <div class="van-card__bottom">-->
    <!--                              <div class="van-card__price">-->
    <!--                                <div>-->
    <!--                                  <span class="van-card__price-currency">¥</span>-->
    <!--                                  <span class="van-card__price-integer">{{ item.price }}</span>-->
    <!--                                </div>-->
    <!--                              </div>-->
    <!--                            </div>-->
    <!--                          </div>-->
    <!--                          <div style="position: absolute;right: 5px; top: 39%;">-->
    <!--                            <van-checkbox v-model="item.checked" icon-size="30px"-->
    <!--                                          @click="productCheckboxToggle(item.id, item.storeName, item)"></van-checkbox>-->
    <!--                          </div>-->
    <!--                        </div>-->
    <!--                      </div>-->
    <!--                    </div>-->
    <!--                  </van-list>-->
    <!--                </van-pull-refresh>-->
    <!--              </van-col>-->
    <!--            </van-row>-->
    <!--            <van-row v-if="empty">-->
    <!--            </van-row>-->

    <!--            <van-image-preview v-model="show" :images="images" @change="onChange">-->
    <!--              <template v-slot:index>第{{ index }}页</template>-->
    <!--            </van-image-preview>-->
    <!--          </div>-->
    <!--        </van-tab>-->
    <!--        <van-tab title="批发价格组">-->
    <!--          <van-empty description="功能暂未开放～"/>-->
    <!--        </van-tab>-->
    <!--      </van-tabs>-->

    <!--      <van-submit-bar button-text="确定" @submit="onProductSubmit">-->
    <!--        <template #tip>-->
    <!--          {{ selectedProductTip }}-->
    <!--        </template>-->
    <!--      </van-submit-bar>-->
    <!--    </van-popup>-->

  </div>
</template>

<script>
import {
  Row,
  Col,
  Icon,
  Cell,
  CellGroup,
  Toast,
  Form,
  Button,
  Field,
  Uploader,
  Popup,
  ContactCard,
  Tag,
  Image as VanImage,
  Card,
  Stepper,
  SubmitBar,
  Divider,
  Checkbox,
  DatetimePicker,
  Sticky,
  Tab,
  Tabs,
  Sidebar,
  SidebarItem,
  Loading,
  PullRefresh,
  ImagePreview,
  List,
  CheckboxGroup,
  Image,
  Notify,
  Empty,
  Cascader,
  Dialog,
  CouponCell
} from 'vant';
import * as productService from "@/api/serverData/getServerData";

import * as adminService from "@/api/admin/login";
import {wechatEvevt} from "@/libs/wechat";
import * as getDataService from "@/api/serverData/getServerData";
import * as adminOrderService from "@/api/admin/order";
import {mapState} from 'vuex'
import $store from "@/store";
import * as service from "@/api/admin/login";



export default {
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Toast.name]: Toast,
    [Form.name]: Form,
    [Button.name]: Button,
    [Field.name]: Field,
    [Uploader.name]: Uploader,
    [Popup.name]: Popup,
    [ContactCard.name]: ContactCard,
    [Tag.name]: Tag,
    [VanImage.name]: VanImage,
    [Card.name]: Card,
    [Stepper.name]: Stepper,
    [SubmitBar.name]: SubmitBar,
    [Divider.name]: Divider,
    [Checkbox.name]: Checkbox,
    [DatetimePicker.name]: DatetimePicker,
    [Sticky.name]: Sticky,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Sidebar.name]: Sidebar,
    [SidebarItem.name]: SidebarItem,
    [Loading.name]: Loading,
    [PullRefresh.name]: PullRefresh,
    [ImagePreview.Component.name]: ImagePreview.Component,
    [List.name]: List,
    [CheckboxGroup.name]: CheckboxGroup,
    [Image.name]: Image,
    [Notify.name]: Notify,
    [Empty.name]: Empty,
    [Cascader.name]: Cascader,
    [CouponCell.name]: CouponCell
  },
  data() {
    return {
      showEditProduct: false,
      selectedProduct: {},
      selectAddressPopupShow: false,
      cascaderValue: '',
      options: [
        {
          text: '浙江省',
          value: '330000',
          children: [{text: '杭州市', value: '330100'}],
        },
        {
          text: '江苏省',
          value: '320000',
          children: [{text: '南京市', value: '320100'}],
        },
      ],
      fieldNames: {
        text: 'n',
        value: 'v',
        children: 'c',
      },


      container: null,
      sidebarContainer: null,
      users: [],
      selectedUserInfo: {},
      orderInfo: {
        orderDatetime: new Date()
      },
      // amountOfOrder: 0,
      candidateProducts: [],
      pattern: /\d{6}/,
      uploader: [{url: 'https://img01.yzcdn.cn/vant/leaf.jpg'}],
      userPopupShow: false,
      showList: false,
      number: '',
      checked: true,
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2025, 10, 1),
      priceStepperValue: 0,
      showPicker: false,
      isShowProductPopup: false,
      productTabsActive: 0,
      selectedProductTip: '',
      selectedProductTipArray: [],
      // selectedProducts: [],

      selectedOrderProductTip: '',
      selectedOrderProductTipArray: [],
      selectedOrderProducts: [],
      empty: false,
      init: true,
      activeKey: 0,
      goods: [],
      items: [],
      show: false,
      showLoading: true,
      index: 0,
      images: [],
      loading: false,
      finished: false,
      refreshing: false,
      pages: {
        perPage: 8,
        currPage: 1,
        pageCount: 3
      },
      itemId: null,

      historyOrders: []
    };
  },
  computed: {
    ...mapState({
      product: state => state.order.product,
      selectedProducts: state => state.order.products,
      orderUser: state => state.order.orderUser,
    }),
    // 箭头函数可使代码更简练

    // 传字符串参数 'count' 等同于 `state => state.count`
    // countAlias: 'count',
    amount: {
      get() {
        return this.$store.state.order.amount;
      },
      set(v) {
        this.$store.state.order.amount = v
      }
    },
    deliveryAddress: {
      get() {
        return this.$store.state.order.orderUser.deliveryAddress;
      },
      set(v) {
        this.$store.state.order.orderUser.deliveryAddress = v
      }
    }
    // 为了能够使用 `this` 获取局部状态，必须使用常规函数
    // countPlusLocalState (state) {
    //   return state.count + this.localCount
    // }
  },
  mounted() {
    this.container = this.$refs.container;
    this.sidebarContainer = this.$refs.sidebarContainer;
  },
  created() {
    const currentTime = new Date();
    this.orderInfo.orderDatetimeStr = `${currentTime.getFullYear()}/${currentTime.getMonth() + 1}/${currentTime.getDate()} ${currentTime.getHours()}:${currentTime.getMinutes()}`;
    getDataService.getCity().then(res => {
      if (res.status === 200) {
        this.options = res.data
      }
    });
  },
  methods: {
    // onSelectedProduct(product) {
    //   product.productId = product.id
    //   product.storeProductAttrValues.forEach((item) => {
    //     item.storeName = product.storeName
    //     item.storeInfo = product.storeInfo
    //     item.productAttrUnique = item.unique
    //   })
    //   this.selectedProduct = product
    //   console.log(this.selectedProduct)
    //   this.showEditProduct = true
    // },
    editProduct(product) {
      this.selectedProduct = product
      this.showEditProduct = true
    },
    submitEditProduct() {
      let amount = 0;
      let number = 0;
      let skuArray = [];
      console.log(this.selectedProduct.storeProductAttrValues)

      if (this.selectedProduct.storeProductAttrValues.length === 0) {
        this.selectedProduct = {}
        this.showEditProduct = false
        this.onChangeAmount()
        return
      }
      this.selectedProduct.storeProductAttrValues.forEach((item, index) => {
        amount += item.price * item.number
        number += item.number;
        item.productAttrUnique = item.unique
        skuArray.push(item.sku);
      })
      this.selectedProduct.sku = skuArray.join(',')
      this.selectedProduct.totalNumber = number
      this.selectedProduct.totalPrice = amount

      this.onChangeAmount()

      // let selectedProduct = this.selectedProducts.filter(item => item.productId === this.selectedProduct.productId);
      // if (selectedProduct === undefined || selectedProduct.length < 1) {
      //   this.selectedProducts.push(this.selectedProduct)
      //   this.showEditProduct = false
      //   this.selectedProduct = {}
      //   return;
      // }
      // this.selectedProduct.storeProductAttrValues.forEach(item => {
      //   let selectedProductAttr = selectedProduct[0].storeProductAttrValues.filter(t => t.unique === item.unique);
      //   if (selectedProductAttr === undefined || selectedProductAttr.length < 1) {
      //     selectedProduct[0].storeProductAttrValues.push(item)
      //     selectedProduct[0].sku = skuArray.join(',')
      //   }
      // })
      //
      // amount = 0;
      // number = 0;
      // skuArray = [];
      // selectedProduct[0].storeProductAttrValues.forEach((item, index) => {
      //   amount += item.price * item.number
      //   number += item.number;
      //   item.productAttrUnique = item.unique
      //   skuArray.push(item.sku);
      // })
      // selectedProduct[0].sku = skuArray.join(',')
      // selectedProduct[0].totalNumber = number
      // selectedProduct[0].totalPrice = amount
      // console.log(this.selectedProducts)
      this.showEditProduct = false
      this.selectedProduct = {}
    },
    onDeleteProductAttr(productAttrUnique) {
      for (let j = 0; j < this.selectedProduct.storeProductAttrValues.length; j++) {
        if (this.selectedProduct.storeProductAttrValues[j].productAttrUnique === productAttrUnique) {
          this.selectedProduct.storeProductAttrValues.splice(j, 1);
        }
      }
      for (let j = 0; j < this.selectedProducts.length; j++) {
        console.log(this.selectedProducts[j].storeProductAttrValues.length)
        if (this.selectedProducts[j].storeProductAttrValues === undefined || this.selectedProducts[j].storeProductAttrValues.length === 0) {
          this.selectedProducts.splice(j, 1);
        }
      }
      this.onChangeAmount()
    },
    onChangeAmount() {
      let amount = 0;
      let number = 0;
      this.selectedProducts.forEach(item => {

        let subAmount = 0;
        let subNumber = 0;
        item.storeProductAttrValues.forEach(subItem => {
          subAmount += subItem.price * subItem.number;
          subNumber += subItem.number;
        })
        item.totalNum = subNumber
        item.totalPrice = subAmount
        amount += subAmount;
        number += subNumber;
      })
      this.amount = amount * 100;
      this.orderInfo.totalNum = number;
      // this.orderInfo.totalPrice = this.amountOfOrder;
    },


    toOrderInfo(orderId) {
      this.$router.push({path: '/order/info/' + orderId})
    },
    deleteOrder(orderId) {
      Dialog.confirm({
        title: '确认删除此订单吗？',
        message: '删除此订，无法找回！',
      })
          .then(() => {
            adminOrderService.deleteAccountOrder(orderId).then(res => {
              if (res.status === 200) {
                Notify({type: 'success', message: '删除成功'});
                this.onRefresh()
              } else {
                Notify({type: 'danger', message: '删除失败'});
              }
            })
            // on confirm
          })
          .catch(() => {
            // on cancel
          });

    },
    toOrderIndo() {
    },
    getAccountOrderInfo(orderId) {
      adminOrderService.getAccountOrderInfo(orderId).then(res => {
        if (res.status === 200) {
          this.orderInfos = res.data
        }
      });
      this.orderInfoPopupShow = true;
    },
    clearPrice(item) {
      item.price = ''
    },
    clearNumber(item) {
      item.number = ''
      console.log('qingchujaige')
      console.log(item)
      console.log(item.number)

    },
    onSelectUser(userId) {
      this.users.forEach(item => {
        if (item.id === userId) {
          this.orderInfo.username = item.username;
          this.orderInfo.phone = item.phone;
          this.orderInfo.userId = item.id;
          this.orderInfo.image = item.avatar;
          this.orderInfo.address = item.address;
          this.orderInfo.deliveryAddress = item.deliveryAddress;
        }
        console.log(item)
        item.isSelected = item.id === userId;
      })
      this.userPopupShow = false;
    },
    refreshHistoryOrderList() {
      // 加载选择用户的历史订单
      if (this.orderInfo.userId !== undefined) {
        adminService.getAccountOrder(this.orderInfo.userId).then(res => {
          this.historyOrders = res;
          this.refreshHistoryOrderSelected()
        })
      }
    },
    refreshHistoryOrderSelected() {
      this.selectedOrderProductTip = '请选择'
      this.selectedOrderProductTipArray = []
      this.selectedOrderProducts = []
    },
    refreshProductSelected() {
      this.selectedProductTip = '请选择'
      this.selectedProductTipArray = []
      this.selectedProducts = []
    },
    refreshProductList() {
      // 加载全部商品
      this.items = [];
      this.goods = [];
      this.refreshProductSelected()
      productService.getAccountItems().then(res => {
        if (res.status === 200) {
          this.init = false;
          this.items = res.data;
          this.showLoading = false;
          if (this.items.length >= 1) {
            this.showLoading = true;
            this.itemId = this.items[0].id;
            this.onRefresh();
          } else {
            this.empty = true;
          }
        } else {
          this.showLoading = false;
          this.finished = true;
        }
      }).catch(error => {
      });
    },
    onSubmitForOrder() {
      this.onChangeAmount()
      let accountOrderInfos = []
      this.selectedProducts.forEach(item => {
        accountOrderInfos.push(item.storeProductAttrValues)
      })
      this.orderInfo.accountOrderInfos = accountOrderInfos;
      this.orderInfo.amount = this.amount;
      this.orderInfo.totalPrice = this.amount / 100;
      this.orderInfo.totalPrice = this.amount / 100;

      this.orderInfo.username = this.orderUser.username
      this.orderInfo.phone = this.orderUser.phone
      console.log(this.orderInfo)

      if (this.orderInfo.username === undefined) {
        Toast("请选择商户")
        return
      }
      if (this.orderInfo.accountOrderInfos.length < 1) {
        Toast("请选择商户购买的商品")
        return
      }

      Dialog.confirm({
        title: '确认修改此订单吗？',
        message: '请仔细检查～',
      })
          .then(() => {
            adminService.addAccount(this.orderInfo).then(res => {
              this.orderInfo.accountOrderInfos = [];
              this.candidateProducts = [];
              this.orderInfo.totalNum = 0;
              this.orderInfo.totalPrice = 0;
              this.amount = 0;
              Notify({type: 'success', message: '新增成功'});
              this.changeCurrentTimeForPicker()
              this.refreshProductList()
              this.refreshHistoryOrderList()
            }).thenReject(res => {
              console.log(4444)
              console.log(res)
              Notify({type: 'danger', message: res});
              this.changeCurrentTimeForPicker()
            }).fail(res => {
              console.log('fail')
            }).catch(res => {
              console.log('catch')
            })
            // on confirm
          })
          .catch(() => {
            // on cancel
          });
    },
    onChangeNumberOfStepper() {
      let amount = 0;
      let number = 0;
      this.candidateProducts.forEach(item => {
        amount += item.price * item.number;
        number += item.number;
      })
      this.amountOfOrder = amount * 100;
      this.orderInfo.totalNum = number;
      this.orderInfo.totalPrice = this.amountOfOrder;
    },
    deleteCandidateProduct(unique) {
      for (let i = 0; i < this.candidateProducts.length; i++) {
        if (this.candidateProducts[i].productAttrUnique === unique) {
          this.candidateProducts.splice(i, 1);
        }
      }

      this.onChangeNumberOfStepper()
    },
    productCheckboxToggle(id, name, item) {

      console.log(this.selectedProductTipArray)
      console.log(this.selectedProducts)
      let selectedProduct = this.goods.filter(item => item.id === id);
      if (selectedProduct === undefined || selectedProduct.length !== 1) {
        return;
      }
      if (item.checked) {
        this.selectedProductTipArray.push(name);
        selectedProduct[0].storeProductAttrValues.forEach(item => {
          this.selectedProducts.push(
              {
                storeName: selectedProduct[0].storeName,
                storeInfo: selectedProduct[0].storeInfo,
                cateId: selectedProduct[0].cateId,
                price: item.price,
                unitName: selectedProduct[0].unitName,
                image: item.image,
                productId: item.productId,
                sku: item.sku,
                productAttrUnique: item.unique,
              }
          )
        })
      } else {
        selectedProduct[0].storeProductAttrValues.forEach(t => {
          for (let j = 0; j < this.selectedProducts.length; j++) {
            if (this.selectedProducts[j].productAttrUnique === t.unique) {
              this.selectedProducts.splice(j, 1);
            }
          }
        })
        for (let i = 0; i < this.selectedProductTipArray.length; i++) {
          if (this.selectedProductTipArray[i] === name) {
            this.selectedProductTipArray.splice(i, 1)
          }
        }
      }
      console.log(this.selectedProducts)
      this.selectedProductTip = this.selectedProductTipArray.join(',');
    },

    // 选择历史订单商品
    productCheckboxGroupToggleForHistoryOrder(item, subItem) {
      if (item.checked) {
        for (let k = 0; k < subItem.length; k++) {
          for (let j = 0; j < this.selectedOrderProducts.length; j++) {
            if (this.selectedOrderProducts[j].productAttrUnique === subItem[k].productAttrUnique) {
              console.log('选择历史订单商品 组 替换价格')
              this.selectedOrderProducts[j].price = subItem[k].price;
              subItem[k].checked = true;
            }
          }
          if (this.selectedOrderProducts.filter(t => t.productAttrUnique === subItem[k].productAttrUnique).length < 1) {
            console.log('选择历史订单商品 组 添加')
            subItem[k].checked = true;

            this.selectedOrderProducts.push({
              storeName: subItem[k].storeName,
              storeInfo: subItem[k].storeInfo,
              cateId: subItem[k].cateId,
              price: subItem[k].price,
              unitName: subItem[k].unitName,
              image: subItem[k].image,
              productId: subItem[k].productId,
              sku: subItem[k].sku,
              productAttrUnique: subItem[k].productAttrUnique,
            })
            this.selectedOrderProductTipArray.push(subItem[k].storeName);
          }
        }
      } else {
        for (let k = 0; k < subItem.length; k++) {
          for (let j = 0; j < this.selectedOrderProducts.length; j++) {
            if (this.selectedOrderProducts[j].productAttrUnique === subItem[k].productAttrUnique) {
              console.log('选择历史订单商品 组 移除')
              this.selectedOrderProducts.splice(j, 1);
            }
          }
          subItem[k].checked = false;
          for (let i = 0; i < this.selectedOrderProductTipArray.length; i++) {
            if (this.selectedOrderProductTipArray[i] === subItem[k].storeName) {
              this.selectedOrderProductTipArray.splice(i, 1);
            }
          }
        }
      }
      this.selectedOrderProductTip = this.selectedOrderProductTipArray.join(',');
      console.log(this.selectedOrderProducts)
    },
    productCheckboxToggleForHistoryOrder(parentId, item) {
      // 遍历 selectedOrderProducts 如果有 productAttrUnique 则删除，否则添加（判断选择状态）
      if (item.checked) {
        console.log('选中')
        for (let j = 0; j < this.selectedOrderProducts.length; j++) {
          if (this.selectedOrderProducts[j].productAttrUnique === item.productAttrUnique) {
            console.log('修改价格')
            this.selectedOrderProducts[j].price = item.price;
          }
        }
        if (this.selectedOrderProducts.filter(t => t.productAttrUnique === item.productAttrUnique).length < 1) {
          console.log('添加')
          this.selectedOrderProducts.push({
            storeName: item.storeName,
            storeInfo: item.storeInfo,
            cateId: item.cateId,
            price: item.price,
            unitName: item.unitName,
            image: item.image,
            productId: item.productId,
            sku: item.sku,
            productAttrUnique: item.productAttrUnique,
          })
          this.selectedOrderProductTipArray.push(item.storeName);
        }
      } else {
        for (let i = 0; i < this.selectedOrderProductTipArray.length; i++) {
          if (this.selectedOrderProductTipArray[i] === item.storeName) {
            this.selectedOrderProductTipArray.splice(i, 1);
          }
        }
        for (let i = 0; i < this.historyOrders.length; i++) {
          if (this.historyOrders[i].id === parentId) {
            this.historyOrders[i].checked = false;
          }
        }
        for (let j = 0; j < this.selectedOrderProducts.length; j++) {
          if (this.selectedOrderProducts[j].productAttrUnique === item.productAttrUnique) {
            console.log('  this.selectedOrderProducts.splice(j, 1);')
            this.selectedOrderProducts.splice(j, 1);
          }
        }
      }
      this.selectedOrderProductTip = this.selectedOrderProductTipArray.join(',');
      console.log(this.selectedOrderProducts)
    },
    showOrderPopup() {
      this.isShowProductPopup = false;
      this.showList = true;
      this.refreshHistoryOrderList()
    },
    showProductPopup() {
      this.$router.push({path: '/order/product'})
      // this.isShowProductPopup = true;
      // this.showList = false;
      // this.refreshProductList()
    },
    onConfirm(time) {
      console.log(time)
      this.orderInfo.orderDatetimeStr = `${time.getFullYear()}/${time.getMonth() + 1}/${time.getDate()} ${time.getHours()}:${time.getMinutes()}`;
      this.orderInfo.orderDatetime = time;
      this.showPicker = false;
    },
    changeCurrentTimeForPicker() {
      const currentTime = new Date();
      console.log(currentTime)
      this.orderInfo.orderDatetimeStr = `${currentTime.getFullYear()}/${currentTime.getMonth() + 1}/${currentTime.getDate()} ${currentTime.getHours()}:${currentTime.getMinutes()}`;
      this.orderInfo.orderDatetime = currentTime;
    },
    onAdd() {
      this.$router.push({path: '/user'})
    },
    // 确认全部商品选择
    onProductSubmit() {
      this.selectedProducts.forEach(item => {
        for (let j = 0; j < this.candidateProducts.length; j++) {
          if (this.candidateProducts[j].productAttrUnique === item.productAttrUnique) {
            console.log('确认全部商品选择替换价格')
            this.candidateProducts[j].price = item.price;
          }
        }
        if (this.candidateProducts.filter(t => t.productAttrUnique === item.productAttrUnique).length < 1) {
          console.log('确认全部商品选择添加')
          item.number = 1;
          this.candidateProducts.push(item)
        }
      })
      this.onChangeNumberOfStepper()
      this.isShowProductPopup = false;
      this.refreshProductSelected()
    },
    // 确认历史订单选择
    onSubmit() {
      this.selectedOrderProducts.forEach(item => {

        for (let j = 0; j < this.candidateProducts.length; j++) {
          if (this.candidateProducts[j].productAttrUnique === item.productAttrUnique) {
            console.log('确认历史订单选择替换价格')
            this.candidateProducts[j].price = item.price;
          }
        }
        if (this.candidateProducts.filter(t => t.productAttrUnique === item.productAttrUnique).length < 1) {
          console.log('确认历史订单选择添加')
          item.number = 1;
          this.candidateProducts.push(item)
        }
      })
      this.onChangeNumberOfStepper()
      this.refreshHistoryOrderSelected()
      this.showList = false;
    },
    showPopup() {

      // this.$router.push({path: '/order/user'})
      // /order/user
      // this.userPopupShow = true;
      //
      service.test().then(res => {
        console.log(res)
        if (res !== undefined) {
          this.users = res;
        } else {
          Toast('加载商户失败')
        }
      }).catch(error => {
      });
    },
    // 校验函数返回 true 表示校验通过，false 表示不通过
    validator(val) {
      return /1\d{10}/.test(val);
    },
    // 异步校验函数返回 Promise
    asyncValidator(val) {
      return new Promise((resolve) => {
        Toast.loading('验证中...');

        setTimeout(() => {
          Toast.clear();
          resolve(/\d{6}/.test(val));
        }, 1000);
      });
    },
    onFailed(errorInfo) {
      console.log('failed', errorInfo);
    },

    showAddAlert(itemId) {
      this.goods = [];
      this.itemId = itemId;
      this.resetData();
      this.onRefresh();
    },
    formatPrice(price) {
      return (price / 100).toFixed(2);
    },
    showImages(id) {
      this.show = !this.show;
      this.goods.forEach(item => {
        if (item.id === id) {
          this.images = item.sliderImage.split(',');
        }
      })
    },
    onChange(index) {
      this.index = index;
    },
    onLoad() {
      console.log('onload')
      console.log(this.goods)

      if (this.refreshing) {
        this.goods = [];
        this.refreshing = false;
      }
      if (this.itemId !== null) {
        this.showLoading = true;
        let param = 'sid=' + this.itemId + '&page=' + this.pages.currPage + '&limit=10';
        if (this.orderInfo.userId !== undefined) {
          param += '&accountUserId=' + this.orderInfo.userId;
        }
        let that = this;
        productService.getAccountProducts(param).then(res => {
          if (res.status === 200) {
            that.goods = that.goods.concat(res.data.records)
            this.showLoading = false;
            this.loading = false;
            that.pages.currPage++;
            that.pages.pageCount = res.data.pages;
            if (that.pages.currPage > that.pages.pageCount) {
              that.finished = true;
            }
          }
        });
      }
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.pages.currPage = 1;
      this.onLoad();
    },
    resetData() {
      this.pages = {
        perPage: 8,
        currPage: 1,
        pageCount: 3
      };
      this.loading = false;
      this.finished = false;
      this.refreshing = false;
    },

    // 全部选项选择完毕后，会触发 finish 事件
    onFinish({selectedOptions}) {
      this.selectAddressPopupShow = false;
      this.deliveryAddress = selectedOptions.map((option) => option.n).join(' ');
      this.orderInfo.address = selectedOptions.map((option) => option.n).join(' ');
      this.orderInfo.deliveryAddress = selectedOptions.map((option) => option.n).join(' ');
    },
    onAreaChange({selectedOptions}) {
      this.deliveryAddress = selectedOptions.map((option) => option.n).join(' ');
      this.orderInfo.address = selectedOptions.map((option) => option.n).join(' ');
      this.orderInfo.deliveryAddress = selectedOptions.map((option) => option.n).join(' ');
    }
  },
};
</script>

<style lang="less">
.van-contact-card1 {
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 16px;
  overflow: hidden;
  color: #323233;
  font-size: 14px;
  line-height: 24px;
  background-color: #fff;
}

.van-contact-card1::before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 2px;
  background: -webkit-repeating-linear-gradient(135deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background: repeating-linear-gradient(-45deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background-size: 80px;
  content: '';
}

.van-contact-card2::before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 2px;
  background: -webkit-repeating-linear-gradient(135deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background: repeating-linear-gradient(-45deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background-size: 80px;
  content: '';
}

.custom-title {
  margin-right: 4px;
  vertical-align: middle;
  display: flex;
  margin-left: 3px;
}

.search-icon {
  font-size: 16px;
  line-height: inherit;
}

.user {
  &-poster {
    width: 100%;
    height: 53vw;
    display: block;
  }

  &-group {
    margin-bottom: 15px;
  }

  &-links {
    padding: 15px 0;
    font-size: 12px;
    text-align: center;
    background-color: #fff;

    .van-icon {
      display: block;
      font-size: 24px;
    }
  }
}


.address-popup-load {
  text-align: center;
  margin: 0 auto;
  padding: 0;
}

.card-goods {
  padding: 10px 0;
  background-color: #fff;

  &__item {
    position: relative;
    background-color: #fafafa;

    .van-checkbox__label {
      width: 100%;
      height: auto; // temp
      padding: 0 10px 0 15px;
      box-sizing: border-box;
    }

    .van-checkbox__icon {
      top: 50%;
      left: 10px;
      z-index: 1;
      position: absolute;
      margin-top: -10px;
    }

    .van-card__price {
      color: #f44;
    }
  }
}

.va-tag-su {
  margin-left: 2px
}
</style>
